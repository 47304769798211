<template>
  <el-aside width="auto" style="background:#fff;">
    <el-menu
      class="menu__wrap el-menu-vertical-demo"
      router
      :default-active="$route.path"
      :collapse="isCollapse"
      :unique-opened="false"
      background-color="#F3F6F8"
    >
      <el-menu-item v-for="(item,index) in routes" :key="index" :index="item.path">
        <div class="menu-item">
          <div >
            <div :class="'icon-'+ (item.path).substr(1)" ></div>
            <span slot="title">{{item.meta.title}}</span>
          </div>
        </div>
      </el-menu-item>
    

    </el-menu>
  </el-aside>
</template>
<script>
import { getStorage } from '@/storage'
export default {
  name: 'LayoutNaviBar',
  props: ['isCollapse'],
  data() {
    return {
      routes: [],
      accountRole: getStorage('accountRole'),
    }
  },
  mounted() {
    this.routes = this.$router.options.routes.slice(3, this.$router.options.routes.length - 1)
  }
}
</script>
<style scoped lang="scss">
.menu__wrap {
  height: 100%;
  box-sizing: border-box;
  &::v-deep {
    .el-menu-item-group__title {
      display: none;
    }
  }
  .menu-icon {
    margin-right: 10px;
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu-item:hover, .el-menu-item:focus {
  background-color:#e6f1fd !important;
}
.menu__wrap ::v-deep .el-menu-item {
  min-width: 199px !important;
}
.handle {
  display: none;
}
</style>
<style >
.el-submenu.is-active > .el-submenu__title{
  color: #007DF2 !important;
}
.el-submenu.is-active > .el-submenu__icon-arrow{
  color: #007DF2 !important;
}
.icon-home {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/home.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-home{
  background-image: url('~@/assets/img/nav/home-active.png');
}
.icon-school {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/company.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-school{
  background-image: url('~@/assets/img/nav/company-active.png');
}
.icon-mien {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/product.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-mien{
  background-image: url('~@/assets/img/nav/product-active.png');
}
.icon-order {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/order.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-order{
  background-image: url('~@/assets/img/nav/order-active.png');
}
.icon-account {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/account.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-account{
  background-image: url('~@/assets/img/nav/account-active.png');
}

</style>
