import Layout from "@/layout/Container.vue"

export const commonRouter = [
  {
    path: '/',
    redirect: '/school'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: "登录",
    },
    component: () => import("@/views/login/index")
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      title: "工作台"
    },
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'HomeIndex',
        meta: {
          title: '数据总览',
          parent: '工作台'
        },
        component: () => import("@/views/home/index")
      },
      
    ]
  },

  {
    path: '/school',
    name: 'School',
    meta: {
      title: "学校管理",
    },
    component: Layout,
    children: [
      {
        path: '/school',
        name: 'SchoolList',
        meta: {
          title: '学校列表',
          parent: '学校管理'
        },
        component: () => import("@/views/school/index")
      },
    ]
  },
  {
    path: '/mien',
    name: 'Mien',
    meta: {
      title: "研学风采",
    },
    component: Layout,
    children: [
      {
        path: '/mien',
        name: 'MienList',
        meta: {
          title: '研学风采',
          parent: '研学风采'
        },
        component: () => import("@/views/mien/index")
      },
    ]
  },
  {
    path: '/order',
    name: 'Order',
    meta: {
      title: "订单管理",
    },
    component: Layout,
    children: [
      {
        path: '/order',
        name: 'OrderList',
        meta: {
          title: '订单列表',
          parent: '订单管理'
        },
        component: () => import("@/views/order/index")
      },
    ]
  },
  {
    path: '/account',
    name: 'Account',
    meta: {
      title: "账号管理",
    },
    component: Layout,
    children: [
      {
        path: '/account',
        name: 'AccountList',
        meta: {
          title: '账号列表',
          parent: '账号管理'
        },
        component: () => import("@/views/account/index")
      },
    ]
  },
  
  
  
  {
    path: '*',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import("@/views/404")
  }
]