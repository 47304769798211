import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment';
import * as $echarts from "echarts";
import "@/plugin/element"
import "normalize.css"
import "element-ui/packages/theme-chalk/src/index.scss";
import "element-ui/lib/theme-chalk/icon.css"
import http from '@/api/http.js'


Vue.prototype.$http = http
Vue.prototype.$echarts = $echarts
Vue.config.productionTip = false
Vue.prototype.$moment = moment

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
